<template>
  <button @click="copyTemplate(props.selTemplate.id)" type="button" class="btn btn-success" :title="$t('receipt.receipteditactions.copytemplate')"><i class="fas fa-copy"></i></button>
  <button v-if="showButEdit" @click="editTemplate(selTemplate)" type="button" class="btn btn-primary" :title="$t('receipt.receipteditactions.edittemplate')">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
    </svg>
  </button>
  <button class="btn btn-primary" @click="showReceiptPrint(props.selTemplate.id)"><font-awesome-icon icon="eye" /></button>
  <button v-if="showButEdit" @click="delTemplate(props.selTemplate.id)" type="button" class="btn btn-danger" :title="$t('receipt.receipteditactions.deltemplate')"><b>X</b></button>
</template>

<script setup>
import {inject, computed, defineProps, defineEmits} from "vue";

const $t = inject('$t')

const props = defineProps({
  selTemplate:  Object
});

const emit = defineEmits(['copyTemplate', 'editTemplate', 'showReceiptPrint', 'delTemplate'])

const content = ''

const access_groups = inject('access_groups')
const set_result = inject('set_result')

const showButEdit = computed(() => props.selTemplate.template_owner !== 'default')
const showButBlock = computed(() => access_groups.value['bill_sub_block'] && props.selBill.status=='active')
const showButDel = computed(() => props.selTemplate.template_owner !== 'default')

function copyTemplate(id)
{
  emit('copyTemplate', {id: id})
}

function editTemplate(id)
{
  emit('editTemplate', id)
}

function showReceiptPrint(id)
{
  emit('showReceiptPrint', id)
}

function delTemplate(id)
{
  emit('delTemplate', id)
}

</script>

<style scoped>
button {
  margin: 3px;
}
</style>