<template>
  <receipt-show-template-modal v-if="show_template_print !==''" :template_id="show_template_print"></receipt-show-template-modal>
  <div v-if="edit_template.id === ''">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: 100,
        perPageDropdown: perPageDropdown,
        nextLabel: $t('app.vue-good-table-next.nextLabel'),
        prevLabel: $t('app.vue-good-table-next.prevLabel'),
        rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
        ofLabel: $t('app.vue-good-table-next.ofLabel'),
        pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
        allLabel: $t('app.vue-good-table-next.allLabel')
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'id', type: 'asc'}
      }"
      :lineNumbers="false"
      compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <receipt-edit-actions
            v-if="props.column.field == 'actions'"
            @copyTemplate="copyTemplate"
            @editTemplate="editTemplate"
            @showReceiptPrint="showReceiptPrint"
            @delTemplate="delTemplate"
            :selTemplate="props.row"
        >
        </receipt-edit-actions>
      </template>
    </vue-good-table>
  </div>
  <div v-if="edit_template.id !== ''" >
    <div class="edit_view">
      <table>
        <tr>
          <td>Name</td><td><input v-model="edit_template.name"></td>
        </tr>
        <tr>
          <td>Head</td><td><vue-editor v-model="edit_template.head" :editor-toolbar="customToolbar"></vue-editor><!--textarea v-model="edit_template.head"></textarea--></td>
        </tr>
        <tr>
          <td>Center</td><td><span v-html="receipt_html"></span></td>
        </tr>
        <tr>
          <td>Bottom</td><td><vue-editor v-model="edit_template.bottom" :editor-toolbar="customToolbar"></vue-editor><!--textarea v-model="edit_template.bottom"></textarea--></td>
        </tr>
      </table>
    </div>
    <div class="ctrl_butts">
      <button class="btn btn-success" @click="saveTemplate">Save</button>
      <button class="btn btn-danger" @click="closeTemplate">Close</button>
      <button class="btn btn-primary" @click="showReceiptPrint(edit_template.id)">View</button>
    </div>
  </div>
</template>

<script setup>
import ReceiptService from "../../services/receipt.service";
import {inject, onMounted, ref, computed, provide} from "vue";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import ReceiptEditActions from "./ReceiptEditActions.vue"
import ReceiptShowTemplateModal from "./ReceiptShowTemplateModal.vue"
import { VueEditor, Quill } from "vue3-editor";

const sortByInt = inject('sortByInt')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

onMounted(getReceiptTemplates)

const $t = inject('$t')
const set_result = inject('set_result')

const test = ref('test')

const receipt_html = ref('')
const show_template_print = ref('')

function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');

fonts.whitelist = fontNames;
Quill.register(fonts, true);

let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
      "content: '" + font + "';" +
      "font-family: '" + font + "', sans-serif;" +
      "}" +
      ".ql-font-" + fontName + "{" +
      " font-family: '" + font + "', sans-serif;" +
      "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

const customToolbar = ref([
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ["bold", "italic", "underline"],
  [{ 'font': fonts.whitelist }],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" }
  ],
  ["image"]
])

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
  {
    label: $t('receipt.receiptedit.templatenum'),
    field: 'id',
    filterOptions: {enabled: true, placeholder: $t('receipt.receiptedit.templatenum')},
    width: '100px',
    tdClass: 'table_text_color',
    sortFn: sortByInt,
  },
  {
    label: $t('receipt.receiptedit.templatename'),
    field: 'name_view',
    filterOptions: {enabled: true, placeholder: $t('receipt.receiptedit.templatename')},
    tdClass: 'table_text_color'
  },
  {
    label: $t('receipt.receiptedit.templateowner'),
    field: 'template_owner',
    filterOptions: {enabled: true, placeholder: $t('receipt.receiptedit.templateowner')},
    tdClass: 'table_text_color'
  },
  {
    label: $t('bill.bills.action'),
    field: 'actions',
    html: true,
    tdClass: 'table_text_color m-0 p-0'
  }
])

const rows = ref([])

function getReceiptPrint (id)
{
  ReceiptService.getTemplatePrint(id).then(
      (response) => {
        edit_template.value.head = response.data.head;
        receipt_html.value = response.data.center;
        edit_template.value.bottom = response.data.bottom;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getReceiptTemplates()
{
  ReceiptService.getReceiptTemplates().then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        rows.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function copyTemplate(id)
{
  ReceiptService.copyTemplate(id).then(
      (response) => {
        getReceiptTemplates()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

const edit_template = ref({id: '', name: '', head: '', bottom: '', 'view_full': ''});

function editTemplate(template)
{
  edit_template.value.id = template.id;
  edit_template.value.name = template.name_view;
  getReceiptPrint(template.id)
}

function closeTemplate()
{
  edit_template.value.id = '';
  edit_template.value.name = '';
  getReceiptTemplates();
}

function saveTemplate()
{
  ReceiptService.saveTemplate(edit_template.value)
}

function showReceiptPrint(id)
{
  show_template_print.value = id
}

function delTemplate(id)
{
  ReceiptService.delTemplate(id).then(
      (response) => {
        getReceiptTemplates()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function close_modal()
{
  show_template_print.value = '';
}

provide('close_modal', close_modal)

</script>

<style scoped>
  .btn
  {
    margin-top: 30px;
    margin-right: 10px;
  }
  .edit_view
  {
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
  }
  .ctrl_butts
  {
    display: flex;
    justify-content: center;
  }
  table
  {
    width: 20% !important;
  }
</style>