<template>
  <div class="hided" :class="{modal_view: template_id !== ''}">
    <center>
      <div class="btt">
        <button @click="close_modal" class="btn btn-danger">Close</button>
      </div>
      <div class="view_template">
        <span v-html="receipt_html"></span>
      </div>
    </center>
  </div>
</template>

<script setup>
import {ref, defineProps, inject, watch, computed} from "vue";
import ReceiptService from "@/services/receipt.service";

const props = defineProps({
  template_id:  String
})

function tplIdFn()
{
  getReceiptPrint()
  return props.template_id
}
const tplId = computed(tplIdFn)

watch(tplId, getReceiptPrint)

const set_result = inject('set_result')
const close_modal = inject('close_modal')

const receipt_html = ref('')

function getReceiptPrint() {
  ReceiptService.getTemplatePrint(props.template_id, 1).then(
      (response) => {
        receipt_html.value = response.data.center;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

</script>

<style scoped>
  .modal_view
  {
    background-color: white;
    height: 80%;
    width: 600px;
    padding: 15px 100px;
    top: 1%;
    left: 38%;
    border: 3px solid;
    border-radius: 10px;
    vertical-align: baseline;
    z-index: 1050;
    position: fixed;
    display: block !important;
    overflow: auto;
  }
  .hided
  {
    display: none;
  }
  .btn
  {
    margin-bottom: 20px;
  }
  .view_template
  {
    height: 90%;
  }
  .btt
  {
    height: 10%;
  }
</style>